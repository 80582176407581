import React                from 'react'
import Helmet               from 'react-helmet'
import { Link }             from 'gatsby'

import config               from '../../data/site-config'

import Layout               from '../components/layout'
import MainWrapper          from '../components/MainWrapper/MainWrapper'
import PageHero             from '../components/PageHero'
import HomeClientLogos      from '../components/HomeFeatures/HomeClientLogos'
import HomeServicesSection  from '../components/HomeFeatures/HomeServicesSection'
import HomeTeamSection      from '../components/HomeFeatures/HomeTeamSection'
import HomeTestimonials     from '../components/HomeFeatures/HomeTestimonials'


class IndexPage extends React.Component {
  render() {
    return (
      <Layout>

        <Helmet
          title={`🏡 Dallas Web Design & User Experience Design • Spacetime`}
          meta={[
            { name: 'description', content: 'Spacetime is a Dallas web design agency crafting unique, head-turning digital experiences. Request a Quote' }
          ]}
          link={[
            { rel: 'canonical', href: `${config.siteUrl}/` }
          ]}
        />

        <PageHero>
          <h2 className="t-headline">We take good ideas&mdash;<span className="o-60">and turn them into fantastic experiences</span></h2>

          <div className="flex-ns mt2">
            <div className="mb5 mb0-ns">
              <h4>Services</h4>
              <p className="white-80 f7 pr5">We believe in results-oriented outcomes achieved through a holistic approach to business problems.</p>
              <Link to="/services/" className="t-small-caps white hover-red">See all services &rarr;</Link>
            </div>
            <div>
              <h4>Clients</h4>
              <p className="white-80 f7 pr5">Dude Perfect, Bell Helicopter, startups and studios&mdash;we've worked with really ground-breaking clients.</p>
              <Link to="/portfolio/" className="t-small-caps white hover-blue">See all client work &rarr;</Link>
            </div>
          </div>

          {/* <Link to="/services/" className="f7 dib br2 white ba pv2 ph3 hover-b--white-30">How we can help &rarr;</Link> */}
        </PageHero>

        <MainWrapper>

          <HomeClientLogos />

          <HomeServicesSection />

          <hr className="b--fog-white" />

          {/* <HomeTeamSection /> */}

          <HomeTestimonials />

        </MainWrapper>

      </Layout>
    );
  }
}

export default IndexPage
