import React    from 'react'
import { Link }         from 'gatsby'

import testimonials from '../../../data/testimonials-data.json'

const HomeTestimonials = () => (

  <section className="pv6 bg-deep-purple">
    <div className="row">
      <h3 className="ttu f7 fw7 tracked pink">&mdash;Testimonials</h3>
      <h4 className="f3 white mb5">Happy <del className="o-40">wife</del> <ins>client</ins>, happy life</h4>
      <ul className="list pa0 ma0 flex-ns justify-between">

        {testimonials.testimonials.slice(0, 2).map(e => (
          <li className="white db mb5 w-100 w-40-ns" key={e.name}>
            <blockquote className="pa0 ma0">

              <p className="f5 white">
                <span style={{marginLeft: "-.5em"}}>&ldquo;</span>{e.quote}&rdquo;
              </p>

              <div className="flex items-center">
                <img src={e.avatar} className="br-100 h3 w3 dib" alt={e.name} />
                <h5 className="lh-copy mv0 ml3">
                  <span className="sans-serif ttu tracked">{e.name}</span>
                  <span className="f7 db o-60">
                    {e.role},&nbsp;
                    <Link to={e.path}
                      className="color-inherit bb b--white-20">
                      {e.business}
                    </Link>
                  </span>
                </h5>
              </div>

            </blockquote>
          </li>
        ))}

      </ul>
    </div>
  </section>

)

export default HomeTestimonials
