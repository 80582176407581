import React       from 'react'
import { Link }         from 'gatsby'
import Emoji       from 'a11y-react-emoji'

import ServiceCard from '../Services/ServiceCard/ServiceCard'

const HomeServicesSection = () => (

  <section className="pv6 bg-white">
    <div className="row mb5">
      <h4 className="ttu f7 fw7 tracked shock-blue mt0">&mdash;Services</h4>
      <h3 className="t-headline">Tell us what you want to achieve,<br />we&rsquo;ll help you get there</h3>
      <p className="measure">We provide a broad range of services&mdash;focusing on smart efforts that act as value multipliers. So, whether you are starting a brand new business or growing to new heights, Spacetime is ready to step-in as a strategic partner for&nbsp;the&nbsp;journey.</p>
      <p>What do you need help with? Pick your poison <Emoji symbol="☠️" label="skull and cross bones"/></p>
    </div>
    <div className="row">
      <div className="ServiceSection__grid">

        <ServiceCard
          name={`technical-seo`}
          title={`Technical SEO`}
          description={`for a lean mean search engine optimization machine`}
          icon={`🔍`}
          iconPath={``}
          bgColor={`#86fc9e`}
          cta={`Crush the competition with unparalleled Technical SEO`} />

        <ServiceCard
          name={`startups`}
          title={`Startups`}
          description={`go from a back-of-the-napkin idea to scalable startup MVP`}
          icon={`🚀`}
          iconPath={``}
          bgColor={`#F03F47`}
          cta={`How to grow your startup`} />

      </div>
    </div>

    <div className="row pt5">
      <Link to="/services/" className="black dim">All Spacetime Services <Emoji symbol="👉" label="pointing hand"/></Link>
    </div>
  </section>

)

export default HomeServicesSection
